export const FirmWareData = [
  {
    id: 1,
    firmwareVersion: "0399331.1",
    compatiableHardware: ["Axons micro 1.0", "Axons micro 2.0"],
    realseDate: "Aug 01th, 2024 07:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 30,
    date: "1 Aug 2024",
    time: "07:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "0399331.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "0399331.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "0399331.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "0399331.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "0399331.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 2,
    firmwareVersion: "0578412.1",
    compatiableHardware: ["Micro 1.0", "Axons pro 2.0"],
    realseDate: "Aug 15th, 2024 07:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 40,
    date: "15 Aug 2024",
    time: "07:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "0578412.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "0578412.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "0578412.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "0578412.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "0578412.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 3,
    firmwareVersion: "8741023.1",
    compatiableHardware: ["Axons micro 1.0"],
    realseDate: "Aug 10th, 2024 01:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 80,
    date: "10 Aug 2024",
    time: "01:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "8741023.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "8741023.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "8741023.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "8741023.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "8741023.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 4,
    firmwareVersion: "8967415.1",
    compatiableHardware: ["Axons micro 1.0", "Pro 2.0"],
    realseDate: "Aug 20th, 2024 05:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 10,
    date: "20 Aug 2024",
    time: "05:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "8967415.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "8967415.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "8967415.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "8967415.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "8967415.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 5,
    firmwareVersion: "8521596.1",
    compatiableHardware: ["Axons micro 1.0", "Pro 2.0"],
    realseDate: "Aug 22th, 2024 08:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 80,
    date: "22 Aug 2024",
    time: "08:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "8521596.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "8521596.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "8521596.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "8521596.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "8521596.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 6,
    firmwareVersion: "7532148.1",
    compatiableHardware: ["Axons micro 1.0", "Pro 2.0", "Micro Pro 2.0"],
    realseDate: "Aug 23th, 2024 10:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 90,
    date: "23 Aug 2024",
    time: "10:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "7532148.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "7532148.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "7532148.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "7532148.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "7532148.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 7,
    firmwareVersion: "7895123.1",
    compatiableHardware: ["Axons micro 1.0"],
    realseDate: "Aug 25th, 2024 12:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 80,
    date: "25 Aug 2024",
    time: "12:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "7895123.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "7895123.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "7895123.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "7895123.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "7895123.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 8,
    firmwareVersion: "9617805.1",
    compatiableHardware: ["Axons micro 1.0", "Pro 2.0", "Micro Pro 2.0"],
    realseDate: "Sep 01th, 2024 13:10:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 50,
    date: "01 Sep 2024",
    time: "13:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "9617805.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "9617805.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "9617805.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "9617805.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "9617805.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 9,
    firmwareVersion: "8564127.1",
    compatiableHardware: ["Axons micro 1.0"],
    realseDate: "Sep 02th, 2024 05:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 30,
    date: "02 Sep 2024",
    time: "05:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "8564127.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "8564127.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "8564127.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "8564127.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "8564127.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 10,
    firmwareVersion: "9517530.1",
    compatiableHardware: ["Axons micro 1.0"],
    realseDate: "Sep 15th, 2024 11:30:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 70,
    date: "10 Sep 2024",
    time: "11:30:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "9517530.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "9517530.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "9517530.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "9517530.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "9517530.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 11,
    firmwareVersion: "7450982.1",
    compatiableHardware: ["Axons micro 1.0"],
    realseDate: "Sep 16th, 2024 08:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 150,
    date: "15 Sep 2024",
    time: "08:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "7450982.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "7450982.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "7450982.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "7450982.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "7450982.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 12,
    firmwareVersion: "1249638.1",
    compatiableHardware: ["Axons micro 1.0"],
    realseDate: "Sep 17th, 2024 12:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 120,
    date: "22 Sep 2024",
    time: "12:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "1249638.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "1249638.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "1249638.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "1249638.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "1249638.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 13,
    firmwareVersion: "7892056.1",
    compatiableHardware: ["Axons micro 1.0"],
    realseDate: "Sep 22th, 2024 10:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 200,
    date: "22 Sep 2024",
    time: "10:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "7892056.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "7892056.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "7892056.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "7892056.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#789456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "7892056.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 14,
    firmwareVersion: "7895632.1",
    compatiableHardware: ["Axons micro 1.0"],
    realseDate: "Sep 25th, 2024 12:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 70,
    date: "25 Sep 2024",
    time: "12:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "7895632.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "7895632.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "7895632.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "7895632.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#458456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "7895632.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
  {
    id: 15,
    firmwareVersion: "7481239.1",
    compatiableHardware: ["Axons micro 1.0"],
    realseDate: "Sep 29th, 2024 08:20:24",
    realseNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    device: 20,
    date: "29 Aug 2024",
    time: "08:20:24",
    axons: [
      {
        slNo: "1",
        iotImei: "AX-01",
        qrCode: "#123456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "2 Jun 2023",
        firstConnectedDate: "2 Apr 2024",
        lastConnectedDate: "2 Jun 2024",
      },
      {
        slNo: "2",
        iotImei: "AX-02",
        qrCode: "#5123456789",
        firmware: "7481239.1",
        status: "Disconnected",
        hardware: "5133459",
        manufacturingDate: "3 Jun 2023",
        firstConnectedDate: "3 Apr 2024",
        lastConnectedDate: "3 Jun 2024",
      },
      {
        slNo: "3",
        iotImei: "AX-03",
        qrCode: "#325456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "1253459",
        manufacturingDate: "4 Jun 2023",
        firstConnectedDate: "4 Apr 2024",
        lastConnectedDate: "4 Jun 2024",
      },
      {
        slNo: "4",
        iotImei: "AX-04",
        qrCode: "#293456789",
        firmware: "7481239.1",
        status: "Disconnected",
        hardware: "8533459",
        manufacturingDate: "5 Jun 2023",
        firstConnectedDate: "5 Apr 2024",
        lastConnectedDate: "5 Jun 2024",
      },
      {
        slNo: "5",
        iotImei: "AX-05",
        qrCode: "#287456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "4733459",
        manufacturingDate: "6 Jun 2023",
        firstConnectedDate: "6 Apr 2024",
        lastConnectedDate: "6 Jun 2024",
      },
      {
        slNo: "6",
        iotImei: "AX-06",
        qrCode: "#754456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "1233459",
        manufacturingDate: "7 Jun 2023",
        firstConnectedDate: "7 Apr 2024",
        lastConnectedDate: "7 Jun 2024",
      },
      {
        slNo: "7",
        iotImei: "AX-07",
        qrCode: "#830456789",
        firmware: "7481239.1",
        status: "Disconnected",
        hardware: "5233459",
        manufacturingDate: "8 Jun 2023",
        firstConnectedDate: "8 Apr 2024",
        lastConnectedDate: "8 Jun 2024",
      },
      {
        slNo: "8",
        iotImei: "AX-08",
        qrCode: "#123456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "0033459",
        manufacturingDate: "9 Jun 2023",
        firstConnectedDate: "9 Apr 2024",
        lastConnectedDate: "9 Jun 2024",
      },
      {
        slNo: "9",
        iotImei: "AX-09",
        qrCode: "#758456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "10 Jun 2023",
        firstConnectedDate: "10 Apr 2024",
        lastConnectedDate: "10 Jun 2024",
      },
      {
        slNo: "10",
        iotImei: "AX-10",
        qrCode: "#588456789",
        firmware: "7481239.1",
        status: "Disconnected",
        hardware: "0033459",
        manufacturingDate: "11 Jun 2023",
        firstConnectedDate: "11 Apr 2024",
        lastConnectedDate: "11 Jun 2024",
      },
      {
        slNo: "11",
        iotImei: "AX-11",
        qrCode: "#660456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "5233459",
        manufacturingDate: "12 Jun 2023",
        firstConnectedDate: "12 Apr 2024",
        lastConnectedDate: "12 Jun 2024",
      },
      {
        slNo: "12",
        iotImei: "AX-12",
        qrCode: "#123456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "2533459",
        manufacturingDate: "13 Jun 2023",
        firstConnectedDate: "13 Apr 2024",
        lastConnectedDate: "13 Jun 2024",
      },
      {
        slNo: "13",
        iotImei: "AX-13",
        qrCode: "#458456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "1333459",
        manufacturingDate: "14 Jun 2023",
        firstConnectedDate: "14 Apr 2024",
        lastConnectedDate: "14 Jun 2024",
      },
      {
        slNo: "14",
        iotImei: "AX-14",
        qrCode: "#144456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "2333459",
        manufacturingDate: "15 Jun 2023",
        firstConnectedDate: "15 Apr 2024",
        lastConnectedDate: "15 Jun 2024",
      },
      {
        slNo: "15",
        iotImei: "AX-15",
        qrCode: "#155456789",
        firmware: "7481239.1",
        status: "Connected",
        hardware: "7533459",
        manufacturingDate: "16 Jun 2023",
        firstConnectedDate: "16 Apr 2024",
        lastConnectedDate: "16 Jun 2024",
      },
    ],
  },
];

export const FirmWaretableCellList = [
  "Firmware Version",
  "Compatible Hardware",
  "Release Date",
  "Release Note",
];

export const AxonstableCellList = [
  "IoT IMEI",
  "Firmware",
  "Status",
  "Hardware",
  "MFG Date",
  "First Connected Date",
  "Last Connected Date",
];

export const SummaryTableCellList = [
  "Version",
  "Selected Devices",
  "Scheduled Time",
  "Comments",
];
