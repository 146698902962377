// src/slices/mapDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  liveDeviceData: [],
};

const mapDataSlice = createSlice({
  name: "mapData",
  initialState,
  reducers: {
    deviceLiveLocation: (state, action) => {
      state.liveDeviceData = action.payload;
    },
  },
});

export const { deviceLiveLocation } = mapDataSlice.actions;
export default mapDataSlice.reducer;
