import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import authReducer from "./slices/authSlice";
import analyticsReducer from "./slices/analyticsSlice";
import mapDataReducer from "./slices/mapDataSlice";
import usersReducer from "./slices/usersSlice";
import devicesReducer from "./slices/devicesSlice";
import settingsReducer from "./slices/settingsSlice";
import subscriberReducer from "./slices/subscriberSlice";
import myOrderReducer from "./slices/myOrderSlice";
import assetReducer from "./slices/assetSlice";
import paymentReducer from "./slices/paymentSlice";
import planDetailReducer from "./slices/planDetailsSlice";
import firmwareReducer from "./slices/firmwareSlice";
import stepperReducer from "./slices/stepperSlice";
import historyReducer from "./slices/updateHistorySlices";
import AuthorityReducer from "./slices/authority"



// Combine all reducers
const rootReducer = combineReducers({
  auth: authReducer,
  analytics: analyticsReducer,
  mapData: mapDataReducer,
  users: usersReducer,
  devices: devicesReducer,
  settings: settingsReducer,
  subscribers: subscriberReducer,
  orders: myOrderReducer,
  assets: assetReducer,
  payment: paymentReducer,
  plan: planDetailReducer,
  firmware: firmwareReducer,
  stepper: stepperReducer,
  updateHistory: historyReducer,
  authority:AuthorityReducer
});

// Configuration for Redux Persist
const persistConfig = {
  key: "root",
  storage,
};

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };

// import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// import authReducer from "./slices/authSlice";
// import analyticsReducer from "./slices/analyticsSlice";
// import mapDataReducer from "./slices/mapDataSlice";
// import usersReducer from "./slices/usersSlice";
// import devicesReducer from "./slices/devicesSlice";
// import settingsReducer from "./slices/settingsSlice";
// import subscriberReducer from "./slices/subscriberSlice";
// import myOrderReducer from './slices/myOrderSlice';
// import assetReducer from './slices/assetSlice';

// // Combine all reducers
// const rootReducer = combineReducers({
//   auth: authReducer,
//   analytics: analyticsReducer,
//   mapData: mapDataReducer,
//   users: usersReducer,
//   devices: devicesReducer,
//   settings: settingsReducer,
//   subscribers: subscriberReducer,
//   orders: myOrderReducer,
//   assets: assetReducer,
// });

// // Configuration for Redux Persist
// const persistConfig = {
//   key: "root",
//   storage,
// };

// // Wrap the root reducer with persistReducer
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Create the store with the persisted reducer and customize middleware
// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         // Ignore actions and paths that include non-serializable values
//         ignoredActions: ['persist/PERSIST'],
//         ignoredPaths: ['register', 'rehydrate'],
//       },
//     }),
// });

// const persistor = persistStore(store);

// export { store, persistor };
