// src/hooks/useApi.js
import { useMutation, useQueryClient } from "react-query";

import api, { FileUploadapi } from "../api";

// Hook for GET request
export const useGetData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const searchParam =
        typeof data.search === "string" ? data.search : data.search?.search;
      // Reconstruct the query params
      const queryString = new URLSearchParams({
        page: data.page || "",
        search: searchParam || "", // Fallback to empty string if no search provided
        status: data.status || "",
      }).toString();

      const response = await api.get(`${endpoint}?${queryString}`);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const useFirmDetailsData = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ endpoint }) => {
      console.log(endpoint, "endpoint value");
      const response = await api.get(endpoint);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("firmdetails");
      },
    }
  );
};
export const useFirmUpdateGetData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const searchParam =
        typeof data.search === "string" ? data.search : data.search?.search;
      console.log(data, "queryparams");
      const queryString = new URLSearchParams({
        page: data.page || "",
        ...(data.search && { search: searchParam || "" }),
        update_firmware_id: data.update_firmware_id,
        ...(data.status && { status: data.status || "" }),
        ...(data.hw_versions && { hw_versions: data.hw_versions }),
        ...(data.fw_versions && { fw_versions: data.fw_versions }),
      });

      const response = await api.get(`${endpoint}?${queryString}`);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

// Hook for POST request
export const usePostData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await api.post(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const usePatchData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await api.patch(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const useDeleteData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint }) => {
      const response = await api.delete(endpoint);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const useUploadFile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      console.log(data, "that id data");
      const response = await FileUploadapi.post(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};
