import { createSlice } from "@reduxjs/toolkit";

const initialState={
    avlaibleUser:[],
    

}

const authoritySlice=createSlice({
    name:"authority",
    initialState,
    reducers:{
        updateAvlaibleUser:(state,action)=>{
            state.avlaibleUser=action.payload
        }
    }
})

export const {updateAvlaibleUser}=authoritySlice.actions
export default authoritySlice.reducer