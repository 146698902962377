import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";

import payngo from "../../assets/image/payngo.svg";
import mobilityondemand from "../../assets/image/mobilityondemand.svg";
import enhancedsecuritysuite from "../../assets/image/enhancedsecuritysuite.svg";
import datainsightsimage from "../../assets/image/datainsightsimage.svg";

import axonsmicro from "../../assets/image/axonmicro.svg";
import axonsmicropro from "../../assets/image/axonmicropro.svg";
import mobility from "../../assets/icons/mobility.svg";
import enhancedSecurity from "../../assets/icons/enhancedsecurity.svg";
import dataInsights from "../../assets/icons/datainsights.svg";
import fleetmanagmnet from "../../assets/image/fleetmanagment.svg";
import deliveryservice from "../../assets/image/deliveryservice.svg";
import {
  setClientSecrateKey,
  setSelectedProducts,
} from "../../slices/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetData, usePostData } from "../../hooks/useApi";
import { setProductPrice } from "../../slices/planDetailsSlice";
import { formatCurrency } from "../Order/util";
import { logout } from "../../slices/authSlice";
import { useSnackbar } from "../../hooks/Snackbar";

export let devicePrices = {
  AxonsMicro: {
    0: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    100: { actualPrice: 800, discountedPrice: 0, savings: 200 },
    200: { actualPrice: 1600, discountedPrice: 0, savings: 400 },
    500: { actualPrice: 4000, discountedPrice: 0, savings: 1000 },
  },
  AxonsMicroPro: {
    0: { actualPrice: 0, discountedPrice: 0, savings: 0 },
    100: { actualPrice: 850, discountedPrice: 0, savings: 200 },
    200: { actualPrice: 1700, discountedPrice: 0, savings: 400 },
    500: { actualPrice: 4250, discountedPrice: 0, savings: 1000 },
  },
};

const services = [
  {
    title: "Pay N Go",
    description:
      "Access flexible mobility and fleet management. Ideal for on-the-move tracking and analytics.",
    imageSrc: payngo,
    detailedDescription:
      "Enhance your mobility experience with our Pay N Go service, integrating efficient ride-sharing capabilities with in-depth analytics on driver behavior and vehicle performance for a seamless micro-mobility solution.",
  },
  {
    title: "Delivery As A Service",
    description:
      "Streamline your fleet and logistics operations. Includes vehicle tracking and comprehensive analytics.",
    imageSrc: deliveryservice,
    detailedDescription:
      "Enhance your delivery efficiency with our Delivery as a Service plan. Benefit from advanced route optimization and automated dispatching while tracking your deliveries in real-time for a streamlined logistic operation.",
  },
  {
    title: "Fleet Management",
    description:
      "Optimize your fleet operations with enhanced management capabilities and real-time insights.",
    imageSrc: fleetmanagmnet,
    detailedDescription:
      "Maximize fleet performance with our Fleet Management plan. This comprehensive approach offers real-time monitoring, predictive maintenance alerts, and detailed usage analytics, ensuring optimal management of your vehicle rentals and fleet operations.",
  },
];

const estimatedCostStyle = {
  fontWeight: 700,
  color: "#283246",
  fontSize: 15,
};

const monthTextClr = {
  color: "#535A6A",
  fontSize: "0.75rem",
  fontWeight: 400,
  fontFamily: "DM Sans, Arial, sans-serif",
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));

const ServiceCard = ({
  imageSrc,
  title,
  description,
  price,
  id,
  details,
  showPaymentButtons,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("/dashboard/service-detail", {
      state: { imageSrc, title, description, price, id, details },
    });
  };

  return (
    <Card
      onClick={showPaymentButtons ? handleCardClick : undefined}
      sx={{
        boxShadow: "none",
        width: "100%",
        height: 279,
        borderRadius: "20px",
        border: "2px solid #DDDEE1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "start",
        cursor: "pointer",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.03)",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          borderColor: "#FF8385",
          "& .hoverBox": {
            bgcolor: "#FFF9F9", // Change the background color of the box
          },
        },
      }}
    >
      <Box sx={{ p: 1, textAlign: "center" }}>
        <img src={imageSrc} height={"147"} alt={title} />
      </Box>
      <Box
        className="hoverBox" // Add a class to this Box
        sx={{ p: 2, bgcolor: "#F3F3F4" }}
      >
        <Typography
          sx={{
            color: "#535A6A",
            fontSize: "0.938rem",
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "#535A6A",
            fontSize: "0.625rem",
            fontWeight: 400,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {description}
        </Typography>
        <Typography
          sx={{
            color: "#283246",
            fontSize: "1.125rem",
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {formatCurrency(price) || 0} <span style={monthTextClr}>/ Month</span>
        </Typography>
      </Box>
    </Card>
  );
};

const FeatureCard = ({
  iconSrc,
  title,
  description,
  image,
  descriptionone,
  descriptiontwo,
  descriptivetitle,
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("/dashboard/feature-detail", {
      state: {
        iconSrc,
        title,
        description,
        descriptionone,
        descriptiontwo,
        descriptivetitle,
        image,
      },
    });
  };

  return (
    <StyledPaper
      sx={{
        my: 1,
        mx: "auto",
        p: 2,
        bgcolor: "rgba(249, 148, 148, 0.1)",
        height: 150,
        boxShadow: "none",
        borderRadius: "20px",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.03)",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          border: "1px solid #FF8385",
        },
      }}
      onClick={handleCardClick}
    >
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar
            sx={{
              bgcolor: "#F99494",
              borderRadius: "12px",
              p: 3,
            }}
          >
            <img src={iconSrc} alt="" />
          </Avatar>
        </Grid>
        <Grid item xs sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "0.938rem",
              fontWeight: 700,
              color: "#535A6A",
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={{ pt: 1 }}>
        <Grid item xs>
          <Typography
            sx={{
              fontSize: "0.625rem",
              fontWeight: 400,
              color: "#535A6A",
            }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};
const descriptionMicro =
  "Order the adaptable Axons Micro for diverse network integration and efficient data handling.";
const descriptionPro =
  "Get the advanced Axons Micro Pro for comprehensive connectivity and superior data management.";
export default function Home() {
  const [quantityMicro, setQuantityMicro] = React.useState(100);
  const [quantityPro, setQuantityPro] = React.useState(100);
  const devicePriceData = useSelector((state) => state.plan.productPrice);
  const [showPayment, setShowPayment] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const postData = usePostData();
  const getData = useGetData();
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  // Function to update the discountedPrice in devicePrices
  function updateDevicePrices(prices, deviceData) {
    const updatedPrices = { ...prices };

    deviceData?.products?.forEach((product) => {
      const { name, price } = product;

      const keyName = name.replace(/\s+/g, "");

      if (updatedPrices[keyName]) {
        Object.keys(updatedPrices[keyName]).forEach((key) => {
          const quantity = parseInt(key, 10);
          updatedPrices[keyName][key].discountedPrice = price * quantity;
        });
      }
    });

    return updatedPrices;
  }

  const updatedDevicePrices = updateDevicePrices(devicePrices, devicePriceData);

  const getClientSecrateKey = async () => {
    try {
      const { data } = await postData.mutateAsync({
        endpoint: "/manage/add-card",
        data: "",
      });

      if (data) {
        setShowPayment(true);
        dispatch(setClientSecrateKey(data.client_secret));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        showSnackbar(
          "Oops! Something went wrong, and you've been logged out.",
          "error"
        );
        dispatch(logout());
        return;
      }
      setShowPayment(false);
      dispatch(setClientSecrateKey(""));
      console.log(error, "error");
    }
  };

  const getProducts = async () => {
    try {
      const { data } = await getData.mutateAsync({
        endpoint: "/manage/products",
        data: "",
      });

      if (data) {
        console.log(data, "dataaa");
        dispatch(setProductPrice(data));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        showSnackbar(
          "Oops! Something went wrong, and you've been logged out.",
          "error"
        );
        dispatch(logout());
        return;
      }
      console.log(error, "error");
      dispatch(setProductPrice(""));
    }
  };

  useEffect(() => {
    getClientSecrateKey();
    getProducts();
    // eslint-disable-next-line
  }, []);

  const handleChangeMicro = (event) => {
    setQuantityMicro(event.target.value);
  };

  const handleChangeMicroPro = (event) => {
    setQuantityPro(event.target.value);
  };

  function calculateCost(quantity, type) {
    return updatedDevicePrices[type][quantity].discountedPrice;
  }

  const estimatedCost = calculateCost(quantityMicro, "AxonsMicro");
  const estimatedCostPro = calculateCost(quantityPro, "AxonsMicroPro");

  const handlePayment = () => {
    let products = [
      {
        name: "Axons Micro",
        price: estimatedCost,
        quantity: quantityMicro,
        description: descriptionMicro,
        image: axonsmicro,
      },
      {
        name: "Axons Micro Pro",
        price: estimatedCostPro,
        quantity: quantityPro,
        description: descriptionPro,
        image: axonsmicropro,
      },
    ];
    // eslint-disable-next-line
    {
      products.map((product, index) => {
        // Find the matching plan
        const matchingPlan = devicePriceData?.products?.find(
          (item) => item.name === product.name
        );

        // If there's a matching plan, add price and id
        if (matchingPlan) {
          product.id = matchingPlan.id;
          product.type = "product";
        }
      });
    }

    // Filter out products with a quantity of 0
    const selectedProducts = products.filter((product) => product.quantity > 0);

    dispatch(setSelectedProducts(selectedProducts));
    if (selectedProducts.length > 0) {
      navigate(`/payment/${selectedProducts[0].id}`);
    }
  };

  return (
    <Box>
      <Box
        sx={{ bgcolor: "#fff", mt: 2, mb: 2, p: 2, borderRadius: "0.625rem" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontFamily: theme.typography.fontFamily,
              fontWeight: 700,
              fontSize: "1.375rem",
              color: "#283246",
            }}
          >
            Choose Your Perfect Plan
          </Typography>
        </Box>

        <Grid container spacing={2} justifyContent="space-between">
          {services.map((service, index) => {
            // Find the matching plan
            const matchingPlan = devicePriceData?.plans?.find(
              (plan) => plan.name === service.title
            );

            // If there's a matching plan, add price and id
            if (matchingPlan) {
              service.price = `${matchingPlan.price.toFixed(2)}`;
              service.id = matchingPlan.id;
            }

            return (
              <Grid item xs={12} md={4} key={index}>
                <ServiceCard
                  imageSrc={service.imageSrc}
                  title={service.title}
                  description={service.description}
                  price={service.price}
                  id={service.id}
                  details={service.detailedDescription}
                  showPaymentButtons={showPayment}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "0.625rem",
          mt: 2,
          mb: 2,
          p: 1,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{ color: "#283246", fontSize: 22, fontWeight: 700, padding: 1 }}
          >
            Place an order
          </Typography>
          <Box sx={{ border: "2px solid #DDDEE1", p: 4, borderRadius: "17px" }}>
            <Grid
              container
              spacing={2}
              columns={12}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2} sx={{}}>
                <Card sx={{ boxShadow: "none", padding: 1 }}>
                  <Box sx={{ minWidth: 135, minHeight: 115 }}>
                    <img
                      width={"100%"}
                      height={"100%"}
                      src={axonsmicro}
                      alt=""
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={4} sx={{ borderRight: "1px solid #D9D9D9" }}>
                <Card sx={{ boxShadow: "none" }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 500,
                        fontFamily: "DM Sans",
                        color: "#000",
                        mb: 0.5, // Added margin-bottom for spacing
                      }}
                    >
                      Axons Micro
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "DM Sans",
                        width: 288,
                        height: 26,
                        lineHeight: "15.86px",
                        color: "#535A6A",
                        mb: 3,
                      }}
                    >
                      {descriptionMicro}
                    </Typography>
                    <FormControl sx={{ mt: 2 }} size="small">
                      <Select
                        sx={{ borderRadius: "12px", width: 148, height: 33 }}
                        onChange={handleChangeMicro}
                        inputProps={{ "aria-label": "Without label" }}
                        value={quantityMicro}
                      >
                        <MenuItem
                          value={0}
                          sx={{
                            width: "100%",
                            maxWidth: 400,
                            fontSize: "0.813rem",
                            color: "#535A6A",
                            fontWeight: 500,
                          }}
                        >
                          0
                        </MenuItem>
                        <MenuItem
                          value={100}
                          sx={{
                            width: "100%",
                            maxWidth: 400,
                            fontSize: "0.813rem",
                            color: "#535A6A",
                            fontWeight: 500,
                          }}
                        >
                          100
                        </MenuItem>
                        <MenuItem
                          value={200}
                          sx={{
                            width: "100%",
                            maxWidth: 400,
                            fontSize: "0.813rem",
                            color: "#535A6A",
                            fontWeight: 500,
                          }}
                        >
                          200
                        </MenuItem>
                        <MenuItem
                          value={500}
                          sx={{
                            width: "100%",
                            maxWidth: 400,
                            fontSize: "0.813rem",
                            color: "#535A6A",
                            fontWeight: 500,
                          }}
                        >
                          500
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      sx={{
                        pt: 3, // Added padding-top for spacing
                        fontSize: 15,
                        fontWeight: 500,
                        fontFamily: "DM Sans",
                        color: "#535A6A",
                      }}
                    >
                      Estimated Cost:{" "}
                      <span style={estimatedCostStyle}>
                        {formatCurrency(estimatedCost)}
                      </span>{" "}
                    </Typography>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={2}>
                <Card sx={{ boxShadow: "none", padding: 1 }}>
                  <Box sx={{ minWidth: 135, minHeight: 115 }}>
                    <img
                      width={"100%"}
                      height={"100%"}
                      src={axonsmicropro}
                      alt=""
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Card sx={{ boxShadow: "none" }}>
                  <Box sx={{}}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 500,
                        fontFamily: "DM Sans",
                        color: "#000",
                        mb: 0.5,
                      }}
                    >
                      Axons Micro Pro
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        fontFamily: "DM Sans",
                        width: 288,
                        height: 26,
                        lineHeight: "15.86px",
                        color: "#535A6A",
                        mb: 3,
                      }}
                    >
                      {descriptionPro}
                    </Typography>
                    <FormControl sx={{ mt: 2 }} size="small">
                      <Select
                        sx={{ borderRadius: "12px", width: 148, height: 33 }}
                        onChange={handleChangeMicroPro}
                        inputProps={{ "aria-label": "Without label" }}
                        value={quantityPro}
                      >
                        <MenuItem
                          value={0}
                          sx={{
                            width: "100%",
                            maxWidth: 400,
                            fontSize: "0.813rem",
                            color: "#535A6A",
                            fontWeight: 500,
                          }}
                        >
                          0
                        </MenuItem>
                        <MenuItem
                          value={100}
                          sx={{
                            width: "100%",
                            maxWidth: 400,
                            fontSize: "0.813rem",
                            color: "#535A6A",
                            fontWeight: 500,
                          }}
                        >
                          100
                        </MenuItem>
                        <MenuItem
                          value={200}
                          sx={{
                            width: "100%",
                            maxWidth: 400,
                            fontSize: "0.813rem",
                            color: "#535A6A",
                            fontWeight: 500,
                          }}
                        >
                          200
                        </MenuItem>
                        <MenuItem
                          value={500}
                          sx={{
                            width: "100%",
                            maxWidth: 400,
                            fontSize: "0.813rem",
                            color: "#535A6A",
                            fontWeight: 500,
                          }}
                        >
                          500
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      sx={{
                        pt: 2,
                        fontSize: 15,
                        fontWeight: 500,
                        fontFamily: "DM Sans",
                        color: "#535A6A",
                      }}
                    >
                      Estimated Cost:{" "}
                      <span style={estimatedCostStyle}>
                        {formatCurrency(estimatedCostPro)}
                      </span>{" "}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <Box sx={{ textAlign: "center", marginTop: 5 }}>
              <Button
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  borderRadius: "12px",
                  width: 342,
                  height: 45,
                  background: "#DDDEE1",
                  color: "#535A6A",
                  border: "1px solid #DDDEE1",
                  textTransform: "none",
                }}
                onClick={showPayment ? handlePayment : null}
              >
                Proceed to Payment
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "0.625rem",
          mt: 2,
          mb: 2,
          p: 2,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{ color: "#283246", fontSize: "1.375rem", fontWeight: 700 }}
          >
            See What's New and Start Exploring
          </Typography>

          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={4}>
              <FeatureCard
                iconSrc={mobility}
                title="Mobility On-Demand Refresh"
                description="The latest update in mobile flexibility and fleet management, featuring advanced tracking and analytics."
                descriptionone="The mobility market is changing fundamentally and at a rapid pace. Every day new offers, services, and technologies are appearing that are expected to revolutionise mobility. Only recently, e-scooters have conquered the market. More and more new mobility platforms offer multimodal information and increasingly also booking options"
                descriptiontwo="Mobility-on-Demand is not a new idea. However, the concept is benefiting from digitisation and the increasing spread of smartphones, because customers and providers can contact each other in the shortest possible time and the offer can be scheduled using digital algorithms instead of analog mental calculation."
                descriptivetitle="Mobility-on-Demand – Less timetable, more flexibility"
                image={mobilityondemand}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard
                iconSrc={enhancedSecurity}
                title="Enhanced Security Suite"
                description="Strengthen your digital infrastructure with cutting-edge security solutions. Our Enhanced Security Suite offers advanced encryption, real-time threat monitoring, and proactive protection to safeguard your network."
                descriptionone="In today’s rapidly evolving digital landscape, securing sensitive information is crucial. Our Enhanced Security Suite ensures comprehensive protection with multi-layered defenses and automated threat responses, keeping your operations safe from emerging cyber risks."
                descriptiontwo="By integrating intelligent security algorithms, our suite minimizes vulnerabilities and enhances your overall resilience against cyber attacks. The future of security lies in advanced analytics, AI-driven monitoring, and real-time response strategies."
                descriptivetitle="Enhanced Security – Robust Protection, Real-Time Threat Response"
                image={enhancedsecuritysuite}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard
                iconSrc={dataInsights}
                title="Data Insights"
                description="Empower your decision-making with dynamic data analytics. Our Data Insights Dashboard provides real-time, customizable visualizations that uncover trends, patterns, and opportunities hidden in your data."
                descriptionone="In a world driven by data, uncovering actionable insights is key to staying competitive. Our Data Insights Dashboard transforms complex data into intuitive visuals, enabling teams to quickly identify trends and optimize their strategies."
                descriptiontwo="Data is the foundation of modern business intelligence. With our tools, you can easily customize dashboards, access real-time metrics, and integrate diverse data sources to make informed, data-driven decisions."
                descriptivetitle="Data Insights – Visualize, Analyze, Optimize"
                image={datainsightsimage}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
