import { createSlice } from "@reduxjs/toolkit";
import { FirmWareData } from "../pages/Firmware/util/index";

const now = new Date();
const hours = String(now.getHours()).padStart(2, "0");
const minutes = String(now.getMinutes()).padStart(2, "0");
const seconds = String(now.getSeconds()).padStart(2, "0");
const currentTime = `${hours}:${minutes}:${seconds}`;

const initialState = {
  FirmwareData: [],
  DeviceData: [],
  selectedFirmwareVersionId: null,
  SelectedDeviceForUpdates: [],

  CurrentScheduleScreen: {
    selectFirmware: true,
    selectDevice: false,
    scheduleDevice: false,
    confirmUpdates: false,
  },
  ScheduleChangesFormData: {
    isScheduleUpdate: false,
    scheduleDate: now,
    scheduleTime: currentTime,
    comments: "",
  },
  StripperState: {
    selectFirmware: true,
    selectDevice: false,
    scheduleUpdates: false,
    confirmUpdates: false,
  },

  FirmwareFilter: {
    screenName: "Select Firmware",
    searchFirmwareVersion: null,
    updateBtn: true,
    updateFirmwareBtn: false,
  },

  DeviceFilter: {
    screenName: "Select Devices",
    searchIMEI: null,
    avlaibleStatus: ["Connected", "Disconnected"],
    currentStatusFilter: null,
    avlaibleHardwareVersion: ["1.1", "1.30", "1.20", "1.40"],
    currentHardwareFilter: [],
    avlaibleFirmwareVersion: ["1.1", "1.30", "1.20", "1.40"],
    currentFirmwareFilter: [],
  },

  ScheduleDeviceList: [],
  SummaryData: {
    version: null,
    deviceCount: 0,
    updateData: now,
    updateTime: currentTime,
    isScheduleUpdate: false,
    comments: "",
  },
};

const firmwareSlice = createSlice({
  name: "firmware",
  initialState,
  reducers: {
    updateFirmwareData: (state, action) => {
      state.FirmwareData = action.payload;
    },

    updateDevcieData: (state, action) => {
      state.DeviceData = action.payload;
    },

    updateSelectedDeviceForUpdates: (state, action) => {
      state.SelectedDeviceForUpdates = action.payload;
    },

    updateCurrentScheduleScreen: (state, action) => {
      state.CurrentScheduleScreen = action.payload;
    },

    updateScheduleChangesFormData: (state, action) => {
      state.ScheduleChangesFormData = action.payload;
    },

    updateStripperState: (state, action) => {
      state.StripperState = action.payload;
    },
    updateFimwareFilter: (state, action) => {
      state.FirmwareFilter = action.payload;
    },
    updateDeviceFilter: (state, action) => {
      state.DeviceFilter.searchIMEI = action.payload;
      console.log("payload", action.payload);

      state.DeviceData = state.DeviceData.data?.filter(
        (device) => device.imei === state.DeviceFilter.searchIMEI
      );
    },
    updateScheduleDeviceList: (state, action) => {
      state.ScheduleDeviceList = action.payload;
    },
    updateSummaryData: (state, action) => {
      state.SummaryData = action.payload;
    },

    updateSelectedFirm: (state, action) => {
      state.selectedFirmwareVersionId = action.payload;
    },
  },
});

export const {
  updateFirmwareData,
  updateDevcieData,
  updateSelectedDeviceForUpdates,
  updateCurrentScheduleScreen,
  updateScheduleChangesFormData,
  updateStripperState,
  updateFimwareFilter,
  updateDeviceFilter,
  updateScheduleDeviceList,
  updateSummaryData,
  updateSelectedFirm,
} = firmwareSlice.actions;
export default firmwareSlice.reducer;
