import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DeleteModal = ({
  open,
  handleClose,
  handleDelete,
  deleteMessage,
  deleteTitle,
}) => {
  const theme = useTheme();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1.5,
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ fontfamil: theme.typography.fontFamily }}
          >
            {deleteTitle}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Typography
          id="modal-description"
          sx={{ mt: 2, fontfamil: theme.typography.fontFamily }}
        >
          {deleteMessage}
        </Typography>
        <Button
          sx={{
            backgroundColor: "rgb(45, 55, 75)",
            color: "common.white",
            width: "10rem",
            padding: "10px 20px", // Normal state padding
            borderRadius: "15px",
            border: "none",
            cursor: "pointer",
            marginTop: "0.625rem",
            marginBottom: "0.625rem",
            textTransform: "none",

            "&:hover": {
              backgroundColor: "rgb(45, 55, 75)", // Maintain the color on hover
              transform: "scale(1.05)", // Slightly increase the size of the button
            },
          }}
          onClick={handleDelete}
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
